<template>
  <b-container :class="$style.wraper">
    <registration-header v-if="!this.$route.query.a"></registration-header>
    <br />
    <registration-body></registration-body>
  </b-container>
</template>

<script>
import RegistrationBody from '@/components/login_and_registration/RegistrationBody.vue';
import RegistrationHeader from '@/components/login_and_registration/RegistrationHeader.vue';

export default {
  components: {
    RegistrationBody,
    RegistrationHeader
  }
};
</script>

<style lang="scss" module>
.wraper {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  max-width: 38rem;
  background: $white;
  text-align: center;
}

@media (min-width: 768px) {
  .wraper {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
</style>
