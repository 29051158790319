<template>
  <div>
    <b-card-text>
      <h2>Регистрация</h2>
      <br />
      <b-form>
        <component :is="isCurrentComponent"></component>
      </b-form>
    </b-card-text>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },

  components: {
    FirstStep: () => import('@/components/login_and_registration/registration_steps/FirstStep.vue'),
    SecondStep: () => import('@/components/login_and_registration/registration_steps/SecondStep.vue')
  },

  computed: {
    ...mapGetters({
      isFirstPage: 'registration/firstPage'
    }),

    isCurrentComponent() {
      return this.isFirstPage ? 'FirstStep' : 'SecondStep';
    }
  }
};
</script>

<style scoped>
div,
p {
  font-size: 1rem;
}

@media (max-width: 766px) {
  div,
  p {
    font-size: 0.875rem;
  }
}
</style>
