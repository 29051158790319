<template>
  <div>
    <img :src="img" :class="$style.logo" alt="Логотип Онлайн Лоджик" />
  </div>
</template>

<script>
import img from '@/assets/login/logo_ru.png';

export default {
  data() {
    return {
      img
    };
  }
};
</script>

<style module>
.logo {
  max-height: 6rem;
}

@media (min-width: 768px) {
  .logo {
    max-width: none;
  }
}
</style>
